import notificationMixin from '@/mixins/notification-mixin';

export default {
  data: () => ({
    active: false,
  }),
  props: {
    object: {
      required: true,
      type: Object,
    },
  },
  mixins: [notificationMixin],
  methods: {
    onEdit() {
      const copy = JSON.parse(JSON.stringify(this.object));
      this.$store.dispatch('openModal', copy);
    },
    async onConfirm() {
      await this.service.delete(this.object.id);
      this.$emit('deleted', this.object);
      this.showMessage('Suppression réussie');
    },
  },
};
