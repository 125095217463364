<template>
  <div>
    <div class="lateral-padding md-layout md-alignment-center">
      <h1 class="md-layout-item">{{ title }}</h1>
      <search-bar class="md-layout-item" @input.native="filter = $event.target.value"/>
    </div>

    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-100">
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-size-10 md-small-20 md-xsmall-size-15"></div>
      </div>
    </md-list-item>

    <md-list>
      <category-item v-for="category of filteredCategories" :key="category.id" :object="category"
                     @deleted="removeObject"/>
    </md-list>

    <category-form @submit="saveObject"/>
  </div>
</template>

<script>

import CategoryItem from '@/components/category/CategoryItem.vue';
import CategoryForm from '@/components/category/CategoryForm.vue';
import CategoryService from '@/components/category/CategoryService';
import SearchBar from '@/components/shared/search-bar/SearchBar.vue';
import pageMixin from '@/mixins/page-mixin';

export default {
  name: 'CategoriesPage',
  data: () => ({
    title: 'Catégories',
    filter: '',
  }),
  components: {
    SearchBar,
    CategoryItem,
    CategoryForm,
  },
  mixins: [pageMixin],
  computed: {
    filteredCategories() {
      if (this.filter) {
        const exp = new RegExp(this.filter.trim(), 'i');
        return this.objects.filter((category) => exp.test(category.name));
      }
      return this.objects;
    },
  },
  async mounted() {
    this.objects = await CategoryService.index();
  },
};
</script>

<style scoped>

</style>
