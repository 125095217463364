import notificationMixin from '@/mixins/notification-mixin';

export default {
  data: () => ({
    objects: [],
    showFilters: true,
  }),
  mixins: [notificationMixin],
  methods: {
    saveObject(newObject) {
      const index = this.objects.findIndex((object) => object.id === newObject.id);
      if (index === -1) {
        this.objects.unshift(newObject);
      } else {
        this.objects.splice(index, 1, newObject);
      }
    },
    removeObject(object) {
      const index = this.objects.indexOf(object);
      this.objects.splice(index, 1);
    },
  },
};
