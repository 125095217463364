<template>
  <div class="search-box">
    <label>
      <input class="search-input" placeholder="Rechercher...">
    </label>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
};
</script>

<style scoped>

.search-box {
  max-width: 300px;
}

.search-input {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTJwdCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSItMSAwIDEzNiAxMzYuMjE4NTIiIHdpZHRoPSIxMnB0Ij4KICAgIDxnIGlkPSJzdXJmYWNlMSI+CiAgICAgICAgPHBhdGggZD0iTSA5My4xNDg0MzggODAuODMyMDMxIEMgMTA5LjUgNTcuNzQyMTg4IDEwNC4wMzEyNSAyNS43Njk1MzEgODAuOTQxNDA2IDkuNDIxODc1IEMgNTcuODUxNTYyIC02LjkyNTc4MSAyNS44Nzg5MDYgLTEuNDYwOTM4IDkuNTMxMjUgMjEuNjMyODEyIEMgLTYuODE2NDA2IDQ0LjcyMjY1NiAtMS4zNTE1NjIgNzYuNjkxNDA2IDIxLjc0MjE4OCA5My4wMzkwNjIgQyAzOC4yMjI2NTYgMTA0LjcwNzAzMSA2MC4wMTE3MTkgMTA1LjYwNTQ2OSA3Ny4zOTQ1MzEgOTUuMzM5ODQ0IEwgMTE1LjE2NDA2MiAxMzIuODgyODEyIEMgMTE5LjI0MjE4OCAxMzcuMTc1NzgxIDEyNi4wMjczNDQgMTM3LjM0NzY1NiAxMzAuMzIwMzEyIDEzMy4yNjk1MzEgQyAxMzQuNjEzMjgxIDEyOS4xOTUzMTIgMTM0Ljc4NTE1NiAxMjIuNDEwMTU2IDEzMC43MTA5MzggMTE4LjExNzE4OCBDIDEzMC41ODIwMzEgMTE3Ljk4MDQ2OSAxMzAuNDU3MDMxIDExNy44NTU0NjkgMTMwLjMyMDMxMiAxMTcuNzI2NTYyIFogTSA1MS4zMDg1OTQgODQuMzMyMDMxIEMgMzMuMDYyNSA4NC4zMzU5MzggMTguMjY5NTMxIDY5LjU1NDY4OCAxOC4yNTc4MTIgNTEuMzA4NTk0IEMgMTguMjUzOTA2IDMzLjA2MjUgMzMuMDM1MTU2IDE4LjI2OTUzMSA1MS4yODUxNTYgMTguMjYxNzE5IEMgNjkuNTA3ODEyIDE4LjI1MzkwNiA4NC4yOTI5NjkgMzMuMDExNzE5IDg0LjMyODEyNSA1MS4yMzQzNzUgQyA4NC4zNTkzNzUgNjkuNDg0Mzc1IDY5LjU4NTkzOCA4NC4zMDA3ODEgNTEuMzMyMDMxIDg0LjMzMjAzMSBDIDUxLjMyNDIxOSA4NC4zMzIwMzEgNTEuMzIwMzEyIDg0LjMzMjAzMSA1MS4zMDg1OTQgODQuMzMyMDMxIFogTSA1MS4zMDg1OTQgODQuMzMyMDMxICIKICAgICAgICAgICAgICBzdHlsZT0iIHN0cm9rZTpub25lO2ZpbGwtcnVsZTpub256ZXJvO2ZpbGw6Izg1ODE4MTtmaWxsLW9wYWNpdHk6MTsiLz4KICAgIDwvZz4KPC9zdmc+Cg==);
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 9px 20px 9px 40px;
}

</style>
