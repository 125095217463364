import { mapGetters } from 'vuex';
import notificationMixin from '@/mixins/notification-mixin';

export default {
  mixins: [notificationMixin],
  computed: {
    ...mapGetters(['isModalVisible', 'object']),
    title() {
      return `${this.object.id ? 'Modifier' : 'Créer'} ${this.model.toLowerCase()}`;
    },
    submitMessage() {
      return this.object.id ? this.updateMessage : this.createMessage;
    },
    createMessage() {
      return `${this.model} créé${this.is_feminine ? 'e' : ''}`;
    },
    updateMessage() {
      return `${this.model} mis${this.is_feminine ? 'e' : ''} à jour`;
    },
  },
  watch: {
    isModalVisible(newValue) {
      if (newValue && !this.object.id) {
        this.$store.dispatch('setObject', this.getDefaultObject());
      }
    },
  },
  methods: {
    getDefaultObject() {
      return {};
    },
    async onSubmit() {
      try {
        const object = await (this.object.id
          ? this.service.update(this.object.id, this.object)
          : await this.service.create(this.object));

        this.showSuccess(this.submitMessage);
        this.$emit('submit', object);
        this.close();
      } catch (err) {
        this.handleError(err);
      }
    },
    close() {
      this.$store.dispatch('closeModal');
    },
  },
};
